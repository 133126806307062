import { CODE_CATEGORY_DIAGNOSIS } from '@/config/constants';
import useCases from '@/hooks/useCases';
import useUser from '@/hooks/useUser';
import { ICode } from '@/types/ICode';
import { hasDuplicateCodesShallow } from '@/utils/codes';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { transaction } from 'mobx';
import { observer } from 'mobx-react';
import { useMemo, useState, useRef } from 'react';
import { FlagDialog } from '../dialogs/FlagDialog';
import { NoDiagnosisWarning } from '../dialogs/NoDiagnosisWarning';
import { SubmitDialog } from '../dialogs/SubmitDialog';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { isAlphaTester } from '@/services/featureFlag';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

export const BottomMenu = observer(() => {
  const navigate = useNavigate();
  const cases = useCases();
  const [submitOpen, setSubmitOpen] = useState(false);
  const [flagOpen, setFlagOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [confirmedNote, setConfirmedNote] = useState(false);
  const [noDiagnosisWarningOpen, setNoDiagnosisWarningOpen] = useState<boolean>(false);
  const user = useUser();
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const hasDiagnosis = () => {
    return cases.selectedCodes.some((code: ICode) => code.type === CODE_CATEGORY_DIAGNOSIS);
  };

  const handleSubmitOpen = () => {
    if (!hasDiagnosis()) {
      setNoDiagnosisWarningOpen(true);
      return;
    }

    setSubmitOpen(true);
  };
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };

  const handleFlagOpen = (confirmedNoteState: boolean) => {
    if (!hasDiagnosis()) {
      setNoDiagnosisWarningOpen(true);
      return;
    }
    setConfirmedNote(confirmedNoteState);
    setFlagOpen(true);
  };
  const handleFlagClose = () => {
    setFlagOpen(false);
  };

  const handleNoDiagnosisWarningAccept = () => {
    // Close No Diagnosis Warning dialog.
    setNoDiagnosisWarningOpen(false);
  };

  const handleSaveChangesClick = () => {
    // Unlock the case first if the case is locked by current user,
    // then save the changes and submit the case to EHR.
    const unlock = cases.isFlagged() && cases.isLockedByMe();
    cases.saveChangesAndSubmitCaseToEHR(unlock).then(() => {
      transaction(() => {
        cases.clearCase();
        cases.disableForceRedirectToNewEhr();
        cases.enableForceRedirectToInbox();
      });
    });
  };

  const submitAndClear = async () => {
    // Unlock the case first if the case is locked by current user,
    // then complete the case and submit it to EHR..
    const unlock = cases.isFlagged() && cases.isLockedByMe();
    cases.completeAndSubmitCaseToEHR(user.initials, unlock).then(() => {
      handleSubmitClose();
      transaction(() => {
        cases.clearCase();
        cases.disableForceRedirectToNewEhr();
        cases.enableForceRedirectToInbox();
      });
    });
  };

  const handleSubmitClick = async () => {
    if (!cases.isNew() || !cases.hasRecordingBeenUsed()) {
      await submitAndClear();
      return;
    }

    await cases.waitForFullAudioAndProcessIt(async () => {
      await submitAndClear();
    });
  };

  const hasDuplicateCodesMemoised = useMemo(
    () => hasDuplicateCodesShallow(cases.selectedCodes),
    [cases.selectedCodes],
  );

  const buttonActions = [
    {
      text: 'Send notat til XMedicus',
      description: 'Send notat til XMedicus',
      action: handleSubmitOpen,
      visibilityCondition: user.isSecretary() && !cases.isDraft(),
    },
    {
      text: 'Gem ændringer',
      description: 'Gem ændringer uden at opdatere XMedicus',
      action: handleSaveChangesClick,
      visibilityCondition: cases.isFlagged(),
    },
    {
      text: 'Afslut notat',
      description: 'Send note to secretary for review',
      action: () => handleFlagOpen(false),
      visibilityCondition: !cases.isFlagged() && !cases.isDraft(),
    },
    {
      text: 'Godkend og afslut notat',
      description: 'Send note to secretary with text approved',
      action: () => handleFlagOpen(true),
      visibilityCondition: false && !cases.isFlagged() && !cases.isDraft(),
    },
    {
      text: 'Recover Note',
      description: 'Recover the draft case and mark as flagged',
      action: () => handleFlagOpen(false),
      visibilityCondition: cases.isDraft(),
    },
    {
      text: 'Slet Notat',
      action: () => setIsArchiveDialogOpen(true),
      visibilityCondition: isAlphaTester(),
    },
  ];

  const primaryButtonAction = buttonActions.find((action) => action.visibilityCondition);
  const nonPrimaryButtonActions = buttonActions.filter(
    (action) => action.visibilityCondition && action.text !== primaryButtonAction.text,
  );

  return (
    <Box
      sx={{
        py: 2,
        px: 2,
        mt: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: 1 }}>
        <ButtonGroup
          variant="contained"
          color="secondary"
          aria-label="Button group with a nested menu"
        >
          <Button onClick={primaryButtonAction.action}>{primaryButtonAction.text}</Button>
          {nonPrimaryButtonActions.length > 0 && (
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              ref={anchorRef}
              onClick={() => setOpen((prevOpen) => !prevOpen)}
            >
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroup>
        <Popper
          sx={{ zIndex: 1 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {buttonActions
                      .filter((v) => v.visibilityCondition && v.text !== primaryButtonAction.text)
                      .map((option) => (
                        <MenuItem key={option.text} onClick={option.action}>
                          {option.text}
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <SubmitDialog
        open={submitOpen}
        handleClose={handleSubmitClose}
        handleSubmit={handleSubmitClick}
        hasDuplicateCodes={hasDuplicateCodesMemoised}
      />
      <FlagDialog
        open={flagOpen}
        confirmedNote={confirmedNote}
        onClose={handleFlagClose}
        hasDuplicateCodes={hasDuplicateCodesMemoised}
      />
      <NoDiagnosisWarning open={noDiagnosisWarningOpen} onAccept={handleNoDiagnosisWarningAccept} />
      <ConfirmDialog
        title="Slet Notat"
        open={isArchiveDialogOpen}
        onAccept={() => cases.deleteCase().then(() => navigate('/'))}
        onCancel={() => setIsArchiveDialogOpen(false)}
      >
        Er du sikker på, at du vil slet denne sag?
      </ConfirmDialog>
    </Box>
  );
});
